import { View } from '@lernetz/ts-lib/src/view/View';
import { Model } from '@lernetz/ts-lib';

import template from './Title.html';


export class Title extends Model {
    title:string;
    level:number;
}


export class TitleView extends View<Title>{
    constructor(){
        super({ template:template });
    }
}
