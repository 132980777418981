import { View } from '@lernetz/ts-lib/src/view/View';
import { Model } from '@lernetz/ts-lib';

import template from './Paragraph.html';


export class Paragraph extends Model{
    text:string;
    widthStyle:string;
    fontStyle:string;
}


export class ParagraphView extends View<Paragraph>{
    constructor(){
        super({ template:template });
    }
}