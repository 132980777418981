import { Model, View } from "@lernetz/ts-lib";

import template from './CompositeArticle.html';

export class CompositeArticle extends Model {
    elements:object[];
    type:string;

    toggleFold() {
        this.type = ( this.type == 'fold-open' ) ? 'fold-close' : 'fold-open';
    }
}


export class CompositeArticleView extends View<CompositeArticle>{
    constructor(){
        super({ template:template });
    }
}
