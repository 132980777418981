import { View } from '@lernetz/ts-lib';
import { datapoint } from './VoteFeedback';
import { translate } from '@lernetz/ts-lib/src/Lang';
import * as d3 from "d3";
import { AxisDomain } from "d3";
import { absoluteBounds } from '@lernetz/ts-lib/src/dom';

export class BarChart extends View<datapoint[]>{

    parentNode:Element;
    

    constructor( public data:datapoint[] ){
        super({ template:'<svg class="svg"></svg>' });
    }

    renderChart( parentNode ){

        this.render();

        this.parentNode = parentNode;
        this.parentNode.appendChild( this.node );
        this.drawChart( this.data );
    }
    

    private responsify( svg, parentNode ){
        // get container + svg aspect ratio
        var container = d3.select(parentNode),
        width = parseInt(svg.style("width")),
        height = parseInt(container.style("height")),
        aspect = width / height;

        // add viewBox and preserveAspectRatio properties,
        // and call resize so that svg resizes on inital page load
        svg.attr("viewBox", "0 0 " + width + " " + height)
            .attr("perserveAspectRatio", "xMinYMid")
            .call(resize);

        // to register multiple listeners for same event type, 
        // you need to add namespace, i.e., 'click.foo'
        // necessary if you call invoke this function for multiple svgs
        // api docs: https://github.com/mbostock/d3/wiki/Selections#on
        d3.select(window).on("resize." + container.attr("id"), resize);

        // get width of container and resize svg to fit it
        function resize() {
            var targetWidth = parseInt(container.style("width"));
            svg.attr("width", targetWidth);
            svg.attr("height", Math.round(targetWidth / aspect));
        }
    }

    private getTooltipText(d) {
        if (d.selected === true ) {
            return translate( 'feedback-same', { data:{ value:d.value, count:d.count }, condition:d.count } );
        }
        else {
            return translate( 'feedback-different', { data:{ value: d.value, count:d.count }, condition:d.count } );
        }
    }

    private toolTipNode(){
        const classname = 'feedback-chart-tooltip';
        const node = d3.select( '.' + classname );
        return ( node.empty() ) ? d3.select( 'body' ).append( 'div' )
                                        .attr( 'class', classname )
                                        .style( 'opacity', 0 ) : node;

    }

    drawChart( data:datapoint[] ){

        //tooltip
        const tooltip = this.toolTipNode();

        let bounds = absoluteBounds( this.parentNode );
        const svg = d3.select(this.node)
                    .attr("width", bounds.width)
                    .attr("height", 300)
                    .call(this.responsify, this.parentNode ),
    
        margin = {
            top: 20,
            right: 20,
            bottom: 20,
            left: 40
        },
        
        width = +svg.attr("width") - margin.left - margin.right,
        height = +svg.attr("height") - margin.top - margin.bottom,
        g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");            

        const x = d3.scaleBand<AxisDomain>()
            .rangeRound([0, width])
            .padding(0.1);

        const y = d3.scaleLinear()
            .rangeRound([height, 0]);

            x.domain(data.map( function (d:datapoint) { return d.value.toString(); }));
            
            y.domain([0, d3.max(data, function (d:datapoint) { return d.count; })]);

            g.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x).tickFormat(d3.format("d")))
            .attr( 'class', 'domain-x');
            /* .append("text")
            .attr("fill", "#525009")
            .attr("y", 22)
            .attr("dy", "0.71em")
            .attr("x", '50%')
            .attr("text-anchor", "middle")
            .attr('class', 'text-style -h5')
            .text(translate('answers')) */

            g.append("g")
            .call(d3.axisLeft(y).ticks(Math.min( 10, d3.max(data, function (d:datapoint) { return d.count; }))).tickFormat(d3.format("d")))
            .attr( 'class', 'domain-y' );
            /* .append("text")
            .attr("fill", "#525009")
            .attr("transform", "rotate(-90)")
            .attr("y", 6)
            .attr("dy", "0.71em")
            .attr("text-anchor", "end")
            .attr('class', 'text-style -h4')
            .text(translate('frequency')); */

            g.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            //tooltip
            .on('mouseenter', (d:datapoint) => {
                tooltip.transition()
                    .duration(200)
                    .style('opacity', 0.9);
                tooltip.html( this.getTooltipText(d) )
                    .style('left', d3.event.pageX + 30 + 'px')
                    .style('top', d3.event.pageY + 'px');
            })
            .on('mouseleave', () => {
                tooltip.transition()
                    .duration(200)
                    .style('opacity', 0);
            })
            .on('mousemove', () => {
                tooltip.style('left', d3.event.pageX + 30 + 'px')
                    .style('top', d3.event.pageY + 'px')
            })
            .attr("class", function (d:datapoint) {
                if (d.selected === true) {
                    return "bar -selected";
                } else {
                    return "bar"
                }
            })
            .attr("x", function (d:datapoint) {
                return x(d.value.toString());
            })
            .attr("y", height)
            .attr("width", x.bandwidth())
        

            .transition()
            .duration(1000)
            .ease(d3.easeExp)
            .attr("y", function (d:datapoint) {
                return y(d.count);
            })
            .attr("height", function (d:datapoint) {
                
                return (height - y(d.count));
            })
            .style('cursor', 'pointer')

    }
}