import { Model, View } from "@lernetz/ts-lib";

import template from './IFrame.html';
import { observeProperties } from "@lernetz/ts-lib/src/Observer";
import { route } from "@lernetz/ts-lib/src/Setup";

export class IFrame extends Model {

    caption:string;
    url:string;
    widthStyle:string;
    embedUrl:string = '';

    constructor(dto) {

        super(dto);

        observeProperties( this, 'embedUrl' );

        route('iframely', { url:this.url }).load()
            .then( ( result:any ) => {
                const index = result.links.player.length - 1;
                this.embedUrl = result.links.player[index].href + '?rel=0';
            });
    }
}

export class IFrameView extends View<IFrame>{

    constructor(){
        super({ template:template });
    }
}
