import { View, Model, Signal } from '@lernetz/ts-lib';
import { Unit } from '../units/Unit';

import template from './UnitList.html';

export class UnitList extends Model{
    
    readonly units:Unit[];

    constructor( units:Unit[] ){
        super({ units:units });
    }
}

export class UnitListView extends View<Model>{

    constructor(){
        super({ template:template });
    }
}