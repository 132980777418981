import { Model, View } from "@lernetz/ts-lib";

import template from './Image.html';
import { Asset } from "../../interfaces/Asset";

export class Image extends Model{
    caption:string;
    image:Asset
}

export class ImageView extends View<Image>{
    constructor(){
        super({
            template:template
        });
    }
}