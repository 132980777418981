import { Model, View } from "@lernetz/ts-lib";
import { Asset } from "../../interfaces/Asset";

import template from './Audio.html';

export class Audio extends Model{
    title:string;
    audio:Asset;
    caption:string;
}

export class AudioView extends View<Audio>{
    constructor(){
        super({ template:template });
    }
}