import { Model, View } from "@lernetz/ts-lib";
import { Asset } from "../../interfaces/Asset";

import template from './Video.html';

export class Video extends Model{
    title:string;
    video:Asset;
    poster:Asset;
    widthStyle:string;
    caption:string;
}

export class VideoView extends View<Video>{
    constructor(){
        super({ template:template });
    }
}