import { Unit } from "../Unit";
import { Progress } from "../../../interfaces/Progress";
import { PositiveExamFeedback } from "./positivefeedback/PositiveExamFeedback";
import { NegativeExamFeedback } from "./negativefeedback/NegativeExamFeedback";
import { SingleChoice } from "../../questions/singlechoice/SingleChoice";
import { Signal } from "@lernetz/ts-lib";
import { isEvaluatedQuestion } from "@lernetz/ui-components/src/questions/Question";
import { ChoiceQuestion } from "@lernetz/ui-components/src/questions/choice/ChoiceQuestion";
import { MultipleChoice } from "../../questions/multiplechoice/MultipleChoice";


export class ExamUnit extends Unit implements Progress{

    successThreshold:number;
    posFeedback:string;
    negFeedback:string;
    feedbackElements:object[] = [];
    examfinished:boolean;
    currentElements:object[] = [];
    questions_positive_feedback:boolean;
    questions_negative_feedback:boolean;

    positiveExamFeedback:PositiveExamFeedback;
    negativeExamFeedback:NegativeExamFeedback;

    onRepeat:Signal<void>;

    constructor(dto){
        super({
            examfinished:false,
            ...dto
        });

        this.positiveExamFeedback = new PositiveExamFeedback({ feedback:this.posFeedback, examresult:'' });
        this.negativeExamFeedback = new NegativeExamFeedback({ feedback:this.negFeedback, examresult:'' });
        this.onRepeat = new Signal<void>();

        this.negativeExamFeedback.onRepeat.subscribe( () => {
            this.repeat();
        });

        this.currentElements = this.elements;
    }

    get progress(){
        return ( this.questions.some( ( q:ChoiceQuestion ) => { return !q.evaluated } ) ) ? 0 : 100;
    }

    private finishExam( passed:boolean, examresult:string ){
        if( passed ){
            this.positiveExamFeedback.examresult = examresult;
            if( this.questions_positive_feedback ) this.positiveExamFeedback.questions = this.questions;
            this.feedbackElements = [
                this.positiveExamFeedback
            ];
        } else {
            this.negativeExamFeedback.examresult = examresult;
            if( this.questions_negative_feedback ) this.negativeExamFeedback.questions = this.questions;
            this.feedbackElements = [
                this.negativeExamFeedback
            ];
        }
        this.evaluateQuestions();
    }

    evaluateExam(){
        const totalQuestions = this.elements.filter( ( element ) => isEvaluatedQuestion( element ) );
        const correctQuestions = totalQuestions.filter( ( element:ChoiceQuestion ) => element.correctlyAnswered );
        const passed = correctQuestions.length >= this.successThreshold;
        const examresult = `${correctQuestions.length}/${totalQuestions.length}`;
        this.examfinished = true;

        this.finishExam( passed, examresult );
        
        this.currentElements = this.feedbackElements;

        document.documentElement.scrollTop = 0;
    }

    repeat(){
        this.questions.forEach( ( question:SingleChoice|MultipleChoice ) => question.reset() );
        this.currentElements = this.elements;
        this.examfinished = false;
        document.documentElement.scrollTop = 0;
    }

    evaluateQuestions(){
        this.questions.forEach( question => {
            question.evaluated = true;
            question.disabled = true;
        });
    }
}
