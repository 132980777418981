import { Unit } from "../Unit";
import { EvaluatedQuestion, isEvaluatedQuestion } from "@lernetz/ui-components/src/questions/Question";
import { Progress } from "../../../interfaces/Progress";
import { dependProperty } from "@lernetz/ts-lib/src/Observer";
import { FreeText } from "../../questions/freetext/FreeText";
import { Tooltip } from "../../questions/tooltip/Tooltip";

export class ProgressUnit extends Unit implements Progress{

    constructor(dto){
        super(dto);

        // replace all evaluated questions with model
        this.elements.forEach( ( element:EvaluatedQuestion, index, elements ) => {
            dependProperty( this, 'progress', element, 'evaluated' );
        });
    }

    shouldBeWrapped( element ){
        return isEvaluatedQuestion( element ) && !( element instanceof FreeText || element instanceof Tooltip )
    }

    get progress():number{
        const evaluatedQuestions = this.questions.filter( ( question:EvaluatedQuestion ) => question.evaluated );
        return ( this.questions.length == 0 ) ? 0 : ( evaluatedQuestions.length / this.questions.length ) * 100;
    }


}
