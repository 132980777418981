import { View } from "@lernetz/ts-lib";
import { MultipleChoice as mc } from '@lernetz/ui-components/src/questions/choice/mc/MultipleChoice';
import { Resetable } from "../../../interfaces/Resetable";

import template from './MultipleChoice.html';

export class MultipleChoice extends mc implements Resetable{

    reset(){
        this.choices.forEach( choice => choice.selected = false );
        this.evaluated = false;
        this.disabled = false;
    }
}

export class MultipleChoiceView extends View<MultipleChoice>{
    constructor(){
        super({ template:template });
    }
}
