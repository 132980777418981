import { Model, View } from "@lernetz/ts-lib";

import template from './CoverImage.html';
import { Asset } from "../../interfaces/Asset";

export class CoverImage extends Model{
    image:Asset
}

export class CoverImageView extends View<CoverImage>{
    constructor(){
        super({
            template:template
        });
    }
}