import { View, Model } from "@lernetz/ts-lib";

import template from './FreeText.html';
import { EvaluatedQuestion, DisabledQuestion } from "@lernetz/ui-components/src/questions/Question";

export class FreeText extends Model implements EvaluatedQuestion, DisabledQuestion{
    title:string;
    text:string;
    feedback:string;
    evaluated:boolean;
    disabled:boolean;

    constructor(dto){
        super({
            evaluated:false,
            disabled:false,
            ...dto
        });
    }
}

export class FreeTextView extends View<FreeText>{
    constructor(){
        super({ template:template });
    }
}