import { Model } from "@lernetz/ts-lib";
import { isEvaluatedQuestion } from "@lernetz/ui-components/src/questions/Question";
import { Asset } from "../../interfaces/Asset";
import { CoverImage } from "../coverimage/CoverImage";
import { UnitInfo } from "../unit-info/UnitInfo";
import { ChoiceQuestion } from "@lernetz/ui-components/src/questions/choice/ChoiceQuestion";

export class Unit extends Model{

    modelName:string;
    id:string;
    slug:string;
    elements:object[];
    cover:Asset;
    title:string;
    duration:number;
    description:string;
    hovered:boolean;


    constructor(dto){
        super({
            hovered:false,
            ...dto
        });

        this.addInfoElements();
    }

    addInfoElements(){
        this.elements.unshift( new UnitInfo({ title:this.title, duration:this.duration, description:this.description }) );

        if( this.cover ){
            this.elements.unshift( new CoverImage({ image:this.cover }) );
        }
    }

    get questions():ChoiceQuestion[]{
        return this.elements.filter( element => isEvaluatedQuestion( element ) ) as ChoiceQuestion[];
    }
}
