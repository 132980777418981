import { Model } from "@lernetz/ts-lib";
import { translate } from '@lernetz/ts-lib/src/Lang';

export class Impressum extends Model{
    
    elements:object[];

    constructor(dto){
        super({
            title:translate( 'impressum' ),
            ...dto
        });
    }
}