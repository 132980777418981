import { Model, View } from "@lernetz/ts-lib";

import scrollIndicatorTemplate from './ScrollIndicator.html';
import { observeProperties } from "@lernetz/ts-lib/src/Observer";

export class ScrollIndicator extends Model{

    updatePercent:() => number;

    constructor(){
        super({});

        this.updatePercent = this.dispatchPercent.bind(this);
        window.addEventListener( 'scroll', this.updatePercent );

        observeProperties( this, 'percent' );
    }

    dispatchPercent(){
        this.propertyChange.dispatch({ name:'percent' });
    }

    get percent():number{
        return Math.max( 0, Math.min( 1, document.documentElement.scrollTop / ( document.body.clientHeight - document.documentElement.clientHeight ) ) ) * 100;
    }
}

export class ScrollIndicatorView extends View<ScrollIndicator>{ 
    constructor(){
        super({ template:scrollIndicatorTemplate, data:new ScrollIndicator() });
    }
}