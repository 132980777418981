export default function group(items: object[], itemTypes: Function[], reduce: (group: object[]) => object|object[]): object[] {
    const result: object[] = [];
    let group: object[] = [];
    for (let i = 0; i < items.length; ++i) {
        const item = items[i];
        if ( isInstance( itemTypes, item ) ) {
            group.push(item);
        }
        else if (group.length) {
            appendTo(result, reduce(group));
            group = [];
            result.push(item);
        }
        else {
            result.push(item);
        }
    }

    if( group.length ) {
        appendTo(result, reduce(group));
    }

    return result;
}

function isInstance( itemTypes:Function[], instance:object ){
    return itemTypes.some( ( itemType:Function ) => {
        return instance instanceof itemType;
    });
}


function appendTo(result: object[], reduced: object|object[]): void {
    if (Array.isArray(reduced)) {
        result.push(...reduced);
    }
    else {
        result.push(reduced);
    }
}