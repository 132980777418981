import { View } from "@lernetz/ts-lib";

/**
 * A helper view that simply renders html into the component node
 */
export class Html extends View<string> {

    styles:object;
    walker:TreeWalker;

	constructor( styles:object ) {
        super( { template:'', data:'' } );
        this.styles = styles;
	}

	init() {
        this.node.innerHTML = this.data;
        this.applyStyles();
        
	}

	update() {
        this.node.innerHTML = this.data;
        this.applyStyles();
    }
    
    applyStyles(){
        const walker = document.createTreeWalker( this.node, NodeFilter.SHOW_ELEMENT );
        while( walker.nextNode() ){
            const key = walker.currentNode.nodeName.toLowerCase();
            if( key in this.styles ){
                const element = walker.currentNode as Element;
                element.classList.add( 'text-style', this.styles[key] );
            }
        }
    }
}