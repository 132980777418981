import { Model, View } from "@lernetz/ts-lib";

import tooltipTempate from './Tooltip.html';
import tipTemplate from './Tip.html';

export class Tooltip extends Model{
    tooltips:Tip[];
    text:string;
    title:string;
}

export class Tip extends Model{
    text:string;
    show:boolean;
    top:number;
    left:number;
    width:number;
    height:number;

    constructor(dto){
        super({
            show:false,
            ...dto
        });
    }
}

export class TooltipView extends View<Tooltip>{
    constructor(){
        super({ template:tooltipTempate });
    }
}

export class TipView extends View<Tip>{
    constructor(){
        super({ template:tipTemplate });
    }

    init(){
        this.node.addEventListener( 'mousemove', function( event:MouseEvent ){
            this.setTextPosition( event );
        }.bind(this));

        this.node.addEventListener( 'mouseenter', function( event:MouseEvent ){
            this.setTextPosition( event );
        }.bind(this));
    }

    setTextPosition( event:MouseEvent ){
        const target = event.target as HTMLElement;
        const delta = 20;
        const posY = event.clientY + delta - target.getBoundingClientRect().top;
        const posX = event.clientX + delta - target.getBoundingClientRect().left;
        this.getContentElement().style.top = posY + 'px';
        this.getContentElement().style.left = posX + 'px';
    }

    getContentElement(){
        return this.node.querySelector( '[js=content]' ) as HTMLElement;
    }
}
