import { View } from '@lernetz/ts-lib';
import { SingleChoice as sc } from '@lernetz/ui-components/src/questions/choice/sc/SingleChoice';
import { Resetable } from '../../../interfaces/Resetable';

import template from './SingleChoice.html';

export class SingleChoice extends sc implements Resetable{

    reset(){
        this.choices.forEach( ch => ch.selected = false );
        this.evaluated = false;
        this.disabled = false;
    }
}

export class SingleChoiceView extends View<SingleChoice>{
    constructor(){
        super({ template:template });
    }
}
