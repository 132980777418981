import { View } from "@lernetz/ts-lib";
import { Feedback } from "../Feedback";
import template from './PositiveExamFeedback.html';

export class PositiveExamFeedback extends Feedback{}

export class PositiveExamFeedbackView extends View{
    constructor(){
        super({ template:template });
    }
}