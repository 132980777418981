import { View, Model } from "@lernetz/ts-lib";
import { route } from "@lernetz/ts-lib/src/Setup";
import { EvaluatedQuestion, DisabledQuestion } from "@lernetz/ui-components/src/questions/Question";
import { observeProperties } from "@lernetz/ts-lib/src/Observer";
import { BarChart } from "./BarChart";
import template from './VoteFeedback.html';

export interface datapoint{
    count:number,
    selected:boolean,
    value:number
}

export class VoteFeedback extends Model implements EvaluatedQuestion, DisabledQuestion{
    readonly id:string;
    title:string;
    text:string;
    feedback:string;
    min:number;
    max:number;
    min_label:string;
    max_label:string;
    current:number;
    disabled:boolean;
    evaluated:boolean;
    feedbackData:datapoint[];

    constructor(dto){
        super({
            disabled:false,
            evaluated:false,
            feedbackData:null,
            ...dto
        });
    }

    updateCurrent( event:MouseEvent ){
        const target = event.target as HTMLInputElement;
        this.current = target.valueAsNumber;
    }

    async evaluateFeedback(){
        if( !this.disabled ){
            this.disabled = true;

            this.evaluated = true;

            const data = {
                votefeedback_id: this.id,
                value: this.current
            }

            // view needs to persist on leaving unit and coming back
            const feedback_data = await route( 'votefeedback' ).send( JSON.stringify( data ) ) as datapoint[];
            this.feedbackData = this.prepareData( feedback_data );

        }
    }

    prepareData( feedbackData ){
        let result:datapoint[] = [];
        for (var i = this.min; i <= this.max; i++) {
            
            if( feedbackData[i] ){
                result.push({ count:feedbackData[i], selected:this.current == i, value:i })
            } else {
                result.push({ count:0, selected:this.current == i, value:i });
            }
        }
        return result;
    }

}

export class VoteFeedbackView extends View<VoteFeedback>{
    
    chart:BarChart = null;

    constructor(){
        super({ template:template });
    }
    
    init(){
        // this.data.propertyChange.filter( arg => arg.name == 'feedbackData' ).subscribe( this.showChart.bind( this ));
        observeProperties( this.data, 'feedbackData' ).propertyChange.filter( arg => arg.name == 'feedbackData' ).subscribe( this.showChart.bind( this ));

        if( this.data.feedbackData ) setTimeout( this.showChart.bind( this ), 250 );
    }

    showChart(){
        this.chart = new BarChart( this.data.feedbackData );
        const chartNode = this.node.querySelector( '.chart' );
        chartNode.innerHTML = '';
        this.chart.renderChart( chartNode );

        // and disable slider
        const slider = this.node.querySelector( '.input-slider' ) as HTMLInputElement;
        slider.disabled = true;
    }


}