import { Model, View } from "@lernetz/ts-lib";

import template from './Foldable.html';
import { observeProperties } from "@lernetz/ts-lib/src/Observer";

export class Foldable extends Model{
    title:string;
    text:string;
    widthStyle:string;
    foldableStyle:string;
    buttonText:string;
    folded:boolean = true;

    constructor(dto){
        super(dto);

        observeProperties( this, "folded" );

    }
}

export class FoldableView extends View<Foldable>{
    constructor(){
        super({ template:template });
    }
}