import { View, Signal } from "@lernetz/ts-lib";
import { Feedback } from "../Feedback";
import template from './NegativeExamFeedback.html';

export class NegativeExamFeedback extends Feedback{
    feedback:string;
    examresult:string;
    onRepeat:Signal<void>;

    constructor(dto){
        super(dto);
        this.onRepeat = new Signal<void>();
    }

    repeat(){
        this.onRepeat.dispatch();
    }
}

export class NegativeExamFeedbackView extends View{
    constructor(){
        super({ template:template });
    }
}