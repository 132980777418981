import { Model, View } from "@lernetz/ts-lib";

import template from './SRFVideo.html';

export class SRFVideo extends Model {

    caption:string;
    url:string;
    widthStyle:string;

    constructor(dto) {

        super(dto);

    }
}

export class SRFVideoView extends View<SRFVideo>{

    constructor(){
        super({ template:template });
    }
}
