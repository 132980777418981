import { Model, View } from "@lernetz/ts-lib";

import template from './MaterialList.html';
import downloadTemplate from './Download.html';
import linkTemplate from './Link.html';


export class MaterialList extends Model {
    elements:Model[];
}

export class MaterialListView extends View<MaterialList>{
    constructor() {
        super({ template:template });

        this.registry.add( 'App\\Download', () => new View({template:downloadTemplate } ) );
        this.registry.add( 'App\\Link', () => new View({template:linkTemplate } ) );
    }
}
